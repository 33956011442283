import { Table, Tag, Skeleton, Button, Drawer, Modal, Form, Input, message } from 'antd'
import { MyContext } from '../App'
import { update } from "../libs/helper"
import dayjs from "dayjs"
import { useState, useContext } from 'react'

const formatXML = (xml) => {
      let formatted = xml
            .replace(/>\s+</g, '><')
            .replace(/(<\/?[^>]+>)/g, '$1\n')
            .trim()

      let indent = 0
      const tab = '    '
      const lines = formatted.split('\n')

      formatted = lines.map(line => {
            if (line.match(/<\//)) {
                  indent--
            }

            const padding = indent >= 0 ? tab.repeat(indent) : ''
            const formattedLine = padding + line

            if (line.match(/<[^/].*>/) && !line.match(/\/>/)) {
                  indent++
            }

            return formattedLine
      }).join('\n')

      return formatted
}

export function ActurisAllErrorsTable({ data, onFixComplete }) {

      const { AppData } = useContext(MyContext)

      const [drawerOpen, setDrawerOpen] = useState(false)
      const [selectedItem, setSelectedItem] = useState(null)
      const [isModalOpen, setIsModalOpen] = useState(false)
      const [isUpdating, setIsUpdating] = useState(false)
      const [form] = Form.useForm()

      const handleFix = (item) => {
            const errorCode = item.response.match(/<ErrorCode>(\d+)<\/ErrorCode>/)?.[1]

            if (errorCode === '9') {
                  const errorMessage = item.response.match(/<ErrorMessage>(.*?)<\/ErrorMessage>/s)?.[1]
                  const versionRef = errorMessage.match(/VersionRef\s+(\d+)/)?.[1]
                  const contactRef = errorMessage.match(/ContactRef\s+(\d+)/)?.[1]

                  form.setFieldsValue({
                        contactRef,
                        versionRef
                  })

                  setIsModalOpen(true)
            } else {
                  Modal.error({
                        title: 'Cannot Fix Error',
                        content: 'Sorry, we cannot yet fix this Error Code.'
                  })
            }
      }

      const onFix = async (values) => {

            setIsUpdating(true)
            console.log('Row Data:', selectedItem)
            console.log('Form Values:', values)

            const policy_number = selectedItem.policy_number

            const data1 = {
                  brand: policy_number.includes("BG") ? "boxguard" : "fastquote",
                  table: 'log_acturis',
                  data: {
                        where: { id: selectedItem.acturis_log_id },
                        data: {
                              status: 'Complete',
                              status_response: 'Code 9 Error Fix',
                              contact_ref: values.contactRef,
                              version_ref: values.versionRef
                        }
                  }
            }

            // Update acturis_log
            let update1 = await update(data1, AppData)


            const data2 = {
                  brand: policy_number.includes("BG") ? "boxguard" : "fastquote",
                  table: 'policies',
                  data: {
                        where: { policy_number },
                        data: {
                              acturis_contact_ref: values.contactRef,
                              acturis_version_ref: values.versionRef
                        }
                  }
            }

            // Update policy record with PVG reference
            let update2 = await update(data2, AppData)

            setIsUpdating(false)

            // Handle user error
            if (update1.status === "success" && update2.status === "success") {
                  setIsModalOpen(false)
                  form.resetFields()
                  message.success(`Great. That looks good. Acturis will take 10-15m to update.`)
                  onFixComplete()

            } else {
                  message.error(`Hmm, something went wrong. Try again.`)
            }
      }

      const generateRowKey = (record) => {
            return `${record.policy_number}-${record.created}`
      }

      const columns = [
            {
                  title: 'Record Created',
                  dataIndex: 'created',
                  key: 'created',
                  render: (text, record) => (
                        <span>{dayjs(record.created).format("HH:mm DD MMM YYYY")} </span>
                  )
            },
            {
                  title: 'Information',
                  key: 'event_type',
                  render: (item) => {
                        let label = ''
                        if (item.event_type === "C") { label = 'CANCELLATION' }
                        else if (item.event_type === "M") { label = 'ADJUSTMENT' }
                        else if (item.event_type === "N") { label = 'NEW BUSINESS' }
                        else { label = 'UNKNOWN' }
                        if (item?.policy_number) {
                              const brand = item.policy_number.includes("BG") ? "BG" : "FQ"
                              const color = item.policy_number.includes("BG") ? "green" : "blue"
                              return (<><Tag color={color}>{brand}</Tag><Tag>{label}</Tag></>)
                        }

                  }
            },
            {
                  title: 'Policy Number',
                  dataIndex: 'policy_number',
                  key: 'policy_number'
            },
            {
                  title: 'Insured',
                  dataIndex: 'legal_entity_name',
                  key: 'legal_entity_name'
            },
            {
                  title: 'Error Information',
                  key: 'action',
                  render: (item) => {
                        const errorCode = item.response.match(/<ErrorCode>(\d+)<\/ErrorCode>/)?.[1]
                        return errorCode
                              ?
                              <>
                                    <Button
                                          className="btn-xs"
                                          style={{ marginTop: 0, marginLeft: '8px', backgroundColor: 'rgb(0, 0, 0)' }}
                                          type="primary"
                                          size="small"
                                          onClick={() => {
                                                setSelectedItem(item)
                                                setDrawerOpen(true)
                                          }}
                                    >
                                          CODE {errorCode}
                                    </Button>
                                    <Button
                                          className="btn-xs"
                                          style={{ marginTop: 0, marginLeft: '8px' }}
                                          type="primary"
                                          size="small"
                                          onClick={() => {
                                                setSelectedItem(item)
                                                handleFix(item)
                                          }}
                                    >
                                          FIX
                                    </Button >
                              </>
                              : null
                  }
            }
      ]

      if (data.length > 0) {

            return (

                  <>
                        <Table
                              rowKey={generateRowKey}
                              style={{ marginTop: '25px' }}
                              columns={columns}
                              dataSource={data}
                              pagination={{ defaultPageSize: 500, showSizeChanger: true, pageSizeOptions: ['25', '50', '100', '500', '1000', '10000'] }}
                        />

                        <Modal
                              title={<h2 style={{ marginBottom: '50px' }}>Check Acturis is correct ..</h2>}
                              open={isModalOpen}
                              onCancel={() => {
                                    setIsModalOpen(false)
                                    form.resetFields()
                              }}
                              footer={null}
                        >
                              <p style={{ fontWeight: 700, marginBottom: 0 }}>{selectedItem?.legal_entity_name}</p>
                              <p style={{ marginBottom: 50 }}>{selectedItem?.policy_number}</p>
                              <Form form={form} onFinish={onFix}>
                                    <Form.Item label="Contact Ref" name="contactRef">
                                          <Input />
                                    </Form.Item>
                                    <Form.Item label="Version Ref" name="versionRef">
                                          <Input />
                                    </Form.Item>
                                    <Form.Item>
                                          <Button type="primary" htmlType="submit">
                                                {isUpdating ? 'Updating...' : 'Yes, this matches'}
                                          </Button>
                                    </Form.Item>
                              </Form>
                        </Modal>

                        <Drawer
                              title="API Request/Response Details"
                              placement="left"
                              onClose={() => setDrawerOpen(false)}
                              open={drawerOpen}
                              width={500}
                        >
                              {selectedItem && (
                                    <>
                                          <h2>API Request</h2>
                                          <pre style={{
                                                whiteSpace: 'pre-wrap',
                                                wordWrap: 'break-word',
                                                fontSize: '9px',
                                                backgroundColor: '#f5f5f5',
                                                padding: '10px',
                                                borderRadius: '4px',
                                                fontFamily: 'monospace'
                                          }}>
                                                {formatXML(selectedItem.request)}
                                          </pre>

                                          <h2>API Response</h2>
                                          <pre style={{
                                                whiteSpace: 'pre-wrap',
                                                wordWrap: 'break-word',
                                                fontSize: '9px',
                                                backgroundColor: '#f5f5f5',
                                                padding: '15px',
                                                borderRadius: '4px',
                                                fontFamily: 'monospace'
                                          }}>
                                                {formatXML(selectedItem.response)}
                                          </pre>
                                    </>
                              )}
                        </Drawer>
                  </>

            )

      } else {

            return (<Skeleton active />)

      }

}
