import { Row, Col, Badge, Skeleton } from "antd"
import { Header } from "../Header"
import { useEffect, useContext, useState } from 'react'
import { ActurisAllErrorsTable } from "../ActurisAllErrorsTable"
import { MyContext } from '../../App'
import axios from "axios"

export function Acturis() {

      const { AppData } = useContext(MyContext)
      const [allErrors, setAllErrors] = useState([])
      const [isLoading, setIsLoading] = useState(false)

      const getActurisErrors = async () => {
            setIsLoading(true)
            const { data } = await axios.get(`${AppData.api.base}/policies/acturis/errors`)
            console.log(data)
            setAllErrors(data.result)
            setIsLoading(false)
      }

      // Gets all unhandled Acturis errors
      useEffect(() => {
            getActurisErrors()
            // eslint-disable-next-line
      }, [])

      const onFixComplete = () => {
            getActurisErrors()
      }

      return (

            <>

                  <Header />

                  <div id="content" className="section">

                        <Row justify="center">

                              <Col span={22}>

                                    <Row className="pagetitle">
                                          <Col>
                                                <h1>Acturis Errors</h1>
                                                <p>"Failure is like a caterpillar before it becomes a butterfly"</p>
                                          </Col>
                                    </Row>

                                    {isLoading
                                          ? <Skeleton active />
                                          : <div className="card" >
                                                <h2>All Business Events - API Errors <Badge color={allErrors.length > 0 ? "#FF4D4F" : "#00813F"} style={{ marginLeft: "10px" }} overflowCount={999} count={allErrors.length} showZero /></h2>
                                                {allErrors.length > 0 ? <ActurisAllErrorsTable data={allErrors} onFixComplete={onFixComplete} /> : null}
                                          </div>}

                              </Col>

                        </Row>

                  </div>

            </>

      )


}
