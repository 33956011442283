import { Row, Col, Skeleton, message, Radio, DatePicker, Select, Table } from "antd"
import { Header } from "../../Header"
import { useEffect, useState, useContext } from "react"
import dayjs from "dayjs"
import { MyContext } from "../../../App"
import axios from "axios"

function CbpfReportSuite() {
    const { AppData, UserData } = useContext(MyContext)
    const [startDate, setStartDate] = useState(dayjs().subtract(AppData.reports.default_period, AppData.reports.default_period_type))
    const [endDate, setEndDate] = useState(dayjs())
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [brand, setBrand] = useState('boxguard')
    const [reportId, setReportId] = useState('03')
    const [columns, setColumns] = useState([])

    const report_types = {
        items: [
            {
                ordinal: 0,
                code: "01",
                description: "Bordereaux"
            },
            {
                ordinal: 1,
                code: "02",
                description: "Loans Received"
            },
            {
                ordinal: 2,
                code: "03",
                description: "Loans in Arrears"
            },
            {
                ordinal: 3,
                code: "04",
                description: "Loans Cancelled"
            },
            {
                ordinal: 4,
                code: "05",
                description: "Loans due for Renewal"
            },
            {
                ordinal: 5,
                code: "06",
                description: "Turnover"
            },
            {
                ordinal: 6,
                code: "07",
                description: "Override"
            },
            {
                ordinal: 7,
                code: "08",
                description: "Loans not yet Confirmed"
            },
            {
                ordinal: 8,
                code: "13",
                description: "Loans Unauthorised"
            },
            {
                ordinal: 9,
                code: "14",
                description: "Indemnities"
            },
            {
                ordinal: 10,
                code: "16",
                description: "Client Changes"
            },
            {
                ordinal: 11,
                code: "17",
                description: "Loan Status Report"
            }
        ].sort((a, b) => a.ordinal - b.ordinal)
    }

    useEffect(() => {
        if (UserData?.report_date_range) {
            setStartDate(dayjs(UserData.report_date_range[0]))
            setEndDate(dayjs(UserData.report_date_range[1]))
        }
    }, [UserData.report_date_range])

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [startDate, endDate, brand, reportId])

    const fetchData = async () => {
        setLoading(true)
        try {
            const params = {
                brand,
                reportId,
                startDate: startDate.format('YYYY-MM-DD'),
                endDate: endDate.format('YYYY-MM-DD')
            }

            console.log('Fetching with params:', params)

            const response = await axios.post(`https://api.dasher.uk/cbpf/report`, params)
            console.log('API Response:', response.data)

            if (response.data.result.reason_code === "API1000") {
                const report_data = response.data.result.report_data
                console.log('Report data:', report_data)
                
                if (report_data && report_data.length > 0) {
                    // Flatten nested objects in the data
                    const flattenedData = report_data.map(item => {
                        const flatItem = {}
                        Object.entries(item).forEach(([key, value]) => {
                            if (value !== null && typeof value === 'object') {
                                // If value is an object, flatten its properties
                                Object.entries(value).forEach(([subKey, subValue]) => {
                                    flatItem[`${key}_${subKey}`] = subValue
                                })
                            } else {
                                flatItem[key] = value
                            }
                        })
                        return flatItem
                    })

                    // Generate columns from flattened data
                    const generatedColumns = Object.keys(flattenedData[0]).map(key => ({
                        title: key.replace(/_/g, ' ')
                            .replace(/^ROW\s+/i, '')
                            .toUpperCase(),
                        dataIndex: key,
                        key: key,
                        render: (text) => {
                            if (text === null || text === undefined) return '-'
                            if (typeof text === 'boolean') return text ? 'Yes' : 'No'
                            if (typeof text === 'object') return JSON.stringify(text)
                            return text.toString()
                        }
                    }))

                    console.log('Generated columns:', generatedColumns)
                    setColumns(generatedColumns)
                    setData(flattenedData)
                } else {
                    setColumns([])
                    setData([])
                    message.info('No data available for the selected criteria')
                }
            } else {
                message.error(response.data.result.reason_description || "Something went wrong")
            }
        } catch (error) {
            console.error('API Error:', error)
            message.error("Failed to fetch data: " + (error.message || "Unknown error"))
        }
        setLoading(false)
    }

    return (
        <>
            <Header />
            <div id="content" className="section">
                <Row justify="center">
                    <Col span={21}>
                        <Row className="pagetitle">
                            <Col>
                                <h1>CBPF : Report Suite</h1>
                            </Col>
                        </Row>

                        <div className="card">
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Radio.Group 
                                        value={brand}
                                        onChange={e => setBrand(e.target.value)}
                                    >
                                        <Radio.Button value="boxguard">Boxguard</Radio.Button>
                                        <Radio.Button value="fastquote">Fastquote</Radio.Button>
                                    </Radio.Group>
                                </Col>

                                <Col span={24}>
                                    <Select
                                        style={{ width: '100%' }}
                                        value={reportId}
                                        onChange={setReportId}
                                        options={report_types.items.map(item => ({
                                            value: item.code,
                                            label: `${item.description} (${item.code})`
                                        }))}
                                    />
                                </Col>

                                <Col span={12}>
                                    <DatePicker
                                        value={startDate}
                                        onChange={setStartDate}
                                        style={{ width: '100%' }}
                                    />
                                </Col>

                                <Col span={12}>
                                    <DatePicker
                                        value={endDate}
                                        onChange={setEndDate}
                                        style={{ width: '100%' }}
                                    />
                                </Col>
                            </Row>

                            <Row style={{ marginTop: '20px' }}>
                                <Col span={24}>
                                    {loading ? (
                                        <Skeleton active />
                                    ) : (
                                        <Table 
                                            columns={columns}
                                            dataSource={data}
                                            scroll={{ x: 'max-content' }}
                                            rowKey={(record, index) => index}
                                            pagination={{
                                                defaultPageSize: 10,
                                                showSizeChanger: true,
                                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                                            }}
                                            locale={{
                                                emptyText: 'No data available'
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export { CbpfReportSuite }
