import { useEffect, useState } from "react"
import { CSVLink } from "react-csv"
import { SearchOutlined } from '@ant-design/icons'
import { HiDownload } from "react-icons/hi"
import { Row, Col, Button, Input, Table as AntTable } from 'antd'
import { Datepicker } from "./Datepicker"

export function Table({ className, data, config }) {

      const width = window.innerWidth

      const [columns, setColumns] = useState([])
      const [filteredData, setFilteredData] = useState(data)

      // Build column object
      useEffect(() => {

            let column_builder = []

            for (let c of config.columns) {

                  column_builder.push({
                        title: c.name,
                        dataIndex: c.key,
                        key: c.key,
                        ...("render" in c && { render: c.render }),
                        ...(c.width && { width: c.width })
                  })

            }

            setColumns(column_builder)

            // eslint-disable-next-line
      }, [])

      function searchRow(s, r) {

            let match = false

            for (const [k, v] of Object.entries(r)) {

                  if (k && v !== null && v.toString().toUpperCase().includes(s.toUpperCase())) {
                        // console.log(`${k}:${v} includes ${s}`)
                        match = true
                        break
                  }

            }

            return match

      }

      function onSearch(e, data) {

            const str = e.target.value

            if (str.length >= 3) {
                  let filterData = data.filter(function (r) { if (searchRow(str, r)) { return true } else { return false } })
                  setFilteredData(filterData)
            } else {
                  setFilteredData(data)
            }

      }

      return (

            <>

                  <Row>

                        <Col xs={24} lg={12}>

                              {config.search.active
                                    ? <Input
                                          size="large"
                                          prefix={<SearchOutlined style={{ opacity: 0.3, marginRight: "10px" }} />}
                                          placeholder="Search for anything .."
                                          onChange={(e) => onSearch(e, data)}
                                          style={width < 992 ? { width: "100%" } : { width: "300px" }}
                                    />
                                    : null}

                        </Col>

                        <Col xs={24} lg={12}>

                              <Row style={width < 992 ? { marginTop: "15px" } : {}}>

                                    <Col flex="auto">

                                          {config.datepicker.active ? <Datepicker config={config} /> : null}

                                    </Col>

                                    {width >= 992
                                          ? <Col flex="50px">

                                                {config.export.active
                                                      ? <Button
                                                            className="btn-xs"
                                                            type="primary"
                                                            style={{ float: "right", marginTop: 0, padding: "5px 8px 0px 8px" }}
                                                      >
                                                            <CSVLink
                                                                  filename={`report.csv`}
                                                                  data={filteredData}
                                                                  className="csv-export-btn"
                                                            >
                                                                  <HiDownload style={{ fontSize: "28px" }} />
                                                            </CSVLink>
                                                      </Button>
                                                      : null}

                                          </Col>
                                          : null}


                              </Row>



                        </Col>

                  </Row>


                  <AntTable className={className} rowClassName={config.onRow.active && "clickable"} rowKey={config?.key ? config.key : 'ref'} style={{ marginTop: '25px' }} columns={columns} dataSource={filteredData} pagination={config.pagination} onRow={config.onRow.active ? (record) => { return { onClick: event => { config.onRow.func(record) } } } : null} />

            </>

      )

} 